<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item
      :label="$t('hat.deviceManagement.batch.clonedDevice')"
      prop="sourceDeviceId"
    >
      <a-select
        show-search
        allowClear
        v-model="form.sourceDeviceId"
        :placeholder="$t('iotScreenManage.pleaseSelect')"
        option-filter-prop="children"
        :filter-option="false"
        @search="v => selectSearch(v)"
      >
        <a-select-option
          v-for="item in deviceList"
          :value="item.id"
          :key="item.id"
        >
          {{ item.userName ? item.value + `(${item.userName})` : item.value }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <span :class="$style.tipText">{{
      $t('hat.deviceManagement.batch.tipOne')
    }}</span>
    <a-form-model-item
      :label="$t('hat.deviceManagement.batch.rule')"
      style="margin-top: 10px;"
    >
      <div style="line-height: 24px">
        <a-checkbox v-model="form.enableLocation">
          {{ $t('hat.deviceManagement.batch.labelOne') }}
        </a-checkbox>
        <div :class="$style.tipText">
          {{ $t('hat.deviceManagement.batch.tipTwo') }}
        </div>
        <a-checkbox v-model="form.enableSos" style="margin-top: 10px;">
          {{ $t('hat.deviceManagement.batch.labelTwo') }}
        </a-checkbox>
        <div :class="$style.tipText">
          {{ $t('hat.deviceManagement.batch.tipThree') }}
        </div>
      </div>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getDeviceList } from '@/services/smart-hat/device-management';
import { Debounce } from 'lodash-decorators';
import { batchUpdateRule } from '@/services/smart-hat/device-management';

@Component()
export default class SettingForm extends Vue {
  @Prop({ type: Array, default: () => [] }) deviceIds;

  form = {
    sourceDeviceId: undefined,
    enableSos: false,
    enableLocation: true,
  };
  rules = {
    sourceDeviceId: [
      {
        required: true,
        message: this.$t('hat.electronFence.required', {
          value: this.$t('hat.deviceManagement.batch.clonedDevice'),
        }),
      },
    ],
  };
  mounted() {
    this.getDeviceList();
  }
  deviceList = [];
  async getDeviceList(content = '') {
    const { records } = await getDeviceList({ current: 1, size: 50, content });
    this.deviceList = records.map(item => ({
      id: item.deviceId,
      value: item.deviceName,
      userName: item.userName,
    }));
  }
  @Debounce(300)
  selectSearch(keyword) {
    this.getDeviceList(keyword);
  }
  async getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            await batchUpdateRule({ ...this.form, deviceIds: this.deviceIds });
            await this.$message.success({
              content: this.$t('hat.deviceManagement.editModal.modified'),
              duration: 0.5,
            });
            resolve(true);
          } catch {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>

<style lang="less" module>
.tipText {
  font-size: 12px;
  line-height: 18px;
  color: #999999;
}
.row {
  .btn {
    cursor: pointer;
    color: var(--primary);
  }
}
:global {
  .ant-checkbox + span {
    padding-left: 0px;
  }
}
</style>
