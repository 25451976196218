<template>
  <a-form-model :model="form" ref="form" :rules="rules">
    <div :class="$style.avatarBox">
      <span
        v-for="item in list"
        :key="item.deviceId"
        :class="$style.avatarWrap"
      >
        <a-tooltip>
          <span slot="title">
            {{ item.userName }}
          </span>
          <x-oss-image :class="$style.avatar" :ossPath="item.avatar" />
        </a-tooltip>
      </span>
    </div>
    <a-form-model-item label="通话成员">
      <div>
        预计可通话{{ list.length }}人/台<span
          >(提示：已过滤不符合设备，请以实际接通为准)</span
        >
      </div>
    </a-form-model-item>
    <a-form-model-item prop="videoType" label="通话模式">
      <a-radio-group v-model="form.videoType">
        <a-radio value="video">视频</a-radio>
        <a-radio value="audio">语音</a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item prop="videoMode" label="巡查模式">
      <a-radio-group v-model="form.videoMode">
        <a-radio :value="VIDEO_MODE.NORMAL">关闭</a-radio>
        <a-radio :value="VIDEO_MODE.SENSORY_MONITORING">有限提醒</a-radio>
        <a-radio :value="VIDEO_MODE.SENSELESS_MONITORING">无提醒</a-radio>
      </a-radio-group>
      <p v-html="tip[form.videoMode]"></p>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VIDEO_MODE } from '@/views/hat/device/manage-model/person-info/person-info.vue';
@Component()
export default class GroupTalkTip extends Vue {
  @Prop({ type: Array }) list;
  VIDEO_MODE = VIDEO_MODE;
  form = {
    videoType: '',
    videoMode: '',
  };
  rules = {
    videoType: [
      {
        required: true,
        message: '通话模式不能为空',
      },
    ],
    videoMode: [
      {
        required: true,
        message: '巡查模式不能为空',
      },
    ],
  };

  tip = {
    [VIDEO_MODE.NORMAL]: '需设备端接听方可收听并查看设备端实时音频、画面',
    [VIDEO_MODE.SENSORY_MONITORING]:
      '自动开启摄像头和麦克风，开始和结束时有语音提示',
    [VIDEO_MODE.SENSELESS_MONITORING]:
      '自动开启设备摄像头和麦克风，期间无任何提示声',
  };

  async getValue() {
    await this.$refs.form.validate();
    return this.form;
  }
}
</script>
<style lang="less" module>
.avatarBox {
  display: flex;
  align-items: center;
  justify-content: center;
  :global {
    .x-oss-image.x-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.avatarWrap + .avatarWrap {
  margin-left: 10px;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
}
</style>
