<template>
  <a-form-model
    layout="vertical"
    :model="form"
    :rules="rules"
    ref="form"
    :class="$style.wrapper"
    style="position: relative;"
  >
    <a-form-model-item
      :label="$t('hat.deviceManagement.form.groupName')"
      prop="groupName"
      v-if="isAddGroup"
    >
      <a-row :gutter="6" :class="$style.row">
        <a-col :span="type === 'add' ? 22 : 24"
          ><a-input
            v-if="isAddGroup"
            :placeholder="$t('hat.deviceManagement.form.inputGroupName')"
            v-model="form.groupName"
            :maxLength="32"
          />
          <a-select
            v-else
            show-search
            allowClear
            v-model="form.groupId"
            :placeholder="$t('hat.deviceManagement.form.select')"
            option-filter-prop="children"
            @change="onChange"
            :filter-option="true"
          >
            <a-select-option
              :value="item.id"
              v-for="item in groupList"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select></a-col
        >
        <!-- <a-col :span="2" v-if="type === 'add'"
          ><span :class="$style.btn" @click="changeButtonType()">{{
            $t('hat.deviceManagement.form.had')
          }}</span></a-col
        > -->
        <a-col :span="2" v-if="type === 'add'"
          ><span :class="$style.btn" @click="changeButtonType()"
            >选择</span
          ></a-col
        >
      </a-row>
    </a-form-model-item>
    <a-form-model-item
      :label="$t('hat.deviceManagement.form.groupName')"
      prop="groupId"
      v-if="!isAddGroup"
    >
      <a-row :gutter="6" :class="$style.row">
        <a-col :span="type === 'add' ? 22 : 24">
          <a-select
            show-search
            allowClear
            v-model="form.groupId"
            :placeholder="$t('hat.deviceManagement.form.select')"
            option-filter-prop="children"
            @change="onChange"
            :filter-option="true"
          >
            <a-select-option
              :value="item.id"
              v-for="item in groupList"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select></a-col
        >
        <a-col :span="2" v-if="type === 'add'"
          ><span :class="$style.btn" @click="changeButtonType()">{{
            $t('hat.deviceManagement.form.new')
          }}</span></a-col
        >
      </a-row>
    </a-form-model-item>
    <a-form-model-item
      :label="$t('hat.deviceManagement.form.principal')"
      prop="user"
    >
      <x-member-select
        v-model="form.user"
        :view="!isAddGroup"
        :value="range"
        @input="updateRangeValue"
        :type="['org-user', 'role-user', 'user']"
        :limit="1"
        :multiple="true"
      />
    </a-form-model-item>
    <a-form-model-item
      :label="$t('hat.deviceManagement.form.selectDevice')"
      prop="devices"
    >
      <a-select
        mode="multiple"
        option-filter-prop="children"
        :filter-option="false"
        v-model="form.devices"
        :class="$style.selectList"
        @change="handleChange"
        @search="v => selectSearch(v)"
      >
        <a-select-option v-for="item in deviceList" :key="item.id">
          {{
            item.name
              ? `${item.deviceCode}(${item.name})`
              : `${item.deviceCode}`
          }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <div :class="$style.tipText">
      {{ $t('hat.deviceManagement.form.groupTip') }}
    </div>
    <a-checkbox v-model="form.isPublic" style="margin-top: 10px;">
      {{ $t('hat.deviceManagement.form.allowJoin') }}
    </a-checkbox>
    <a-checkbox v-model="form.groupCallAuth" style="margin-top: 10px;">
      允许编组内的Plus设备发起群组通话（连按<img
        :class="$style.audioIcon"
        src="@/assets/images/broadcastAudio.png"
      />3次发起）
    </a-checkbox>
    <a-spin :spinning="loading" />
  </a-form-model>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import Tip from '@/components/tip';
import {
  getGroupById,
  getDeviceList,
  getGroupList,
  deleteGroup,
} from '@/services/smart-hat/device-management';
import { Debounce } from 'lodash-decorators';

@Component()
export default class GroupingForm extends Vue {
  @Prop({ type: String, default: 'add' }) type;
  @Prop({ type: Object, default: () => {} }) data;
  @Prop({ type: Array, default: () => [] }) selectData;
  async mounted() {
    await this.getDeviceList();
    if (this.type === 'edit') {
      await this.getGroupInfo(this.data.groupId);
    }
    if (this.type === 'add') {
      this.form.isPublic = true;
      await this.getGroupList();
    }
  }
  form = {
    groupName: '',
    groupId: undefined,
    user: [],
    devices: [],
    isPublic: false,
    groupCallAuth: false,
  };
  rules = {
    groupName: [
      {
        required: true,
        message: this.$t('hat.deviceManagement.form.requiredGroupName'),
      },
    ],
    groupId: [
      {
        required: true,
        message: this.$t('hat.deviceManagement.form.requiredGroupId'),
      },
    ],
    user: [
      {
        required: true,
        message: this.$t('hat.deviceManagement.form.requiredPrincipal'),
      },
    ],
    devices: [
      {
        required: false,
        message: this.$t('hat.deviceManagement.form.requiredDevice'),
      },
    ],
  };
  handleChange() {}
  loading = false;
  async getGroupInfo(id) {
    this.loading = true;
    try {
      const data = await getGroupById(id);
      if (this.type === 'add') {
        this.form.devices = [
          ...this.selectData
            .filter(item => item.groupId === '')
            .map(item => item.deviceId),
          ...data.deviceInfo.map(item => item.deviceId),
        ];
      } else {
        this.form.devices = [...data.deviceInfo.map(item => item.deviceId)];
      }
      this.deviceList = [
        ...data.deviceInfo.map(item => ({
          id: item.deviceId,
          name: item.userName,
          deviceCode: item.deviceName,
        })),
        ...this.selectedList,
      ];
      this.form.groupName = data.deviceGroupName;
      this.form.user = data.principalInfo.map(item => ({
        text: item.principalName,
        value: item.principalId,
        avatar: item.principalPicture,
        type: 'user',
      }));
      this.form.isPublic = data.isPublic;
      this.form.groupCallAuth = data.groupCallAuth;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  deviceList = [];
  async getDeviceList(keyword) {
    try {
      const data = {
        current: 1,
        size: 200,
        content: keyword,
      };
      this.deviceList = (await getDeviceList(data)).records
        .filter(item => item.groupId === '')
        .map(item => ({
          id: item.deviceId,
          name: item.userName,
          deviceCode: item.deviceName,
        }));
      this.selectedList = [...this.deviceList];
      if (this.type === 'add') {
        this.form.devices = this.selectData
          .filter(item => item.groupId === '')
          .map(item => item.deviceId);
      }
    } catch {
      return false;
    }
  }
  @Debounce(300)
  selectSearch(keyword) {
    this.getDeviceList(keyword);
  }
  groupList = [];
  async getGroupList() {
    try {
      const data = {
        current: 1,
        size: 200,
      };
      this.groupList = (await getGroupList(data)).records.map(item => ({
        id: item.pkId,
        name: item.deviceGroupName,
      }));
    } catch {
      return false;
    }
  }
  deleteGroup() {
    const text = `确认删除编组"${this.data.groupName}"`;
    const tips = '删除后编组负责人将无法管理对应设备，无线对讲频道将失效！';
    return new Promise((resolve, reject) => {
      try {
        createFormModal(
          () => (
            <Tip iconStyle={{ padding: '0 0 22px' }}>
              <template slot="txt">
                <span>{text}</span>
              </template>
              <span slot="subTxt">{tips}</span>
            </Tip>
          ),
          {
            width: '442px',
            title: '提示',
            onOk: async () => {
              try {
                await deleteGroup(this.data.groupId);
                this.$message.success('删除成功!');
                resolve(true);
              } catch {
                reject(false);
              }
            },
          },
        );
      } catch {
        resolve(false);
      }
    });
  }
  async onChange(v) {
    this.getGroupInfo(v);
  }
  isAddGroup = true;
  changeButtonType() {
    this.$refs.form.resetFields();
    this.form.groupName = '';
    this.form.groupId = undefined;
    this.form.user = [];
    this.isAddGroup = !this.isAddGroup;
    this.$emit('changeOperationType', this.isAddGroup);
    this.deviceList = this.selectedList;
    this.form.devices = this.selectData
      .filter(item => item.groupId === '')
      .map(item => item.deviceId);
  }
  // 选择成员
  range = [];
  view = false;
  updateRangeValue(data) {
    this.range = data[0];
  }
  async getValue() {
    await this.$refs.form.validate();
    if (this.form.user.length > 20) {
      this.$message.error('最多只能添加20个编组负责人！');
      return false;
    }
    if (this.form.devices.length > 200) {
      this.$message.error('最多只能添加200台设备！');
      return false;
    }
    const data = {
      groupName: this.form.groupName,
      idxDeviceId: this.form.devices,
      idxPrincipalId: this.form.user.map(item => item.value),
      isPublic: this.form.isPublic,
      groupCallAuth: this.form.groupCallAuth,
    };
    const flag = this.type === 'add' && this.isAddGroup;
    if (this.type === 'edit') {
      data.groupId = this.data.groupId;
    }
    if (!this.isAddGroup) {
      data.groupId = this.form.groupId;
    }
    const str = flag ? '添加成功' : '修改成功';
    return {
      flag,
      data,
      str,
    };
  }
}
</script>

<style lang="less" module>
.tipText {
  color: var(--info);
}
.row {
  .btn {
    cursor: pointer;
    color: var(--primary);
  }
}
.selectList {
  :global {
    .ant-select-selection--multiple {
      height: 140px;
      overflow-y: auto;
    }
  }
}
.wrapper {
  :global {
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
}
.audioIcon {
  width: 20px;
  height: 20px;
  margin: 0 2px;
  vertical-align: text-top;
}
</style>
